import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["field"]

  update(event) {
    var isDirty = event.target.value != (event.target.dataset.dirtyOriginalValue || "")
    event.target.dataset.isDirty = isDirty
    this.markDirtyOnForm()
  }

  markDirtyOnForm() {
    var isDirty = false
    this.fieldTargets.forEach(target => {
      if(target.dataset.isDirty) {
        isDirty = true
        return false
      }
    })
    this.element.dataset.isDirty = isDirty
  }
}
