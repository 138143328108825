import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["lastSavedAt", "form", "select"]

  save() {
    var autosaveField = document.createElement("INPUT")
    autosaveField.name = "autosave"
    autosaveField.value = "1"
    autosaveField.type = "hidden"
    this.formTarget.appendChild(autosaveField)

    $(this.formTarget).trigger('submit.rails');
  }

  connect() {
    // For autosaving relations and files
    $(this.formTarget).on('FilePond:processfile', () => {
      this.save()
    })
    this.selectTargets.forEach(target => {
      $(target).on('change.select2', () => {
        this.save()
      })
    })

    $(this.formTarget).on('ajax:success', (event, data, status, xhr) => {
      this.setLastSavedAt(data["updated_at"])
      if (data["url"]) {
        this.formTarget.action = data["url"]
        this.formTarget.method = "patch"
      }
    })

    window.load_selects();
    $('.datetimepicker').datetimepicker();
  }

  updateTime(event) {
    this.setLastSavedAt(event.detail['updated_at'])
  }

  setLastSavedAt(time) {
    this.lastSavedAtTarget.innerHTML = time
  }
}
