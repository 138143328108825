import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form"]

  perform(event) {
    if(this.shouldChangeForm()) {
      var id = event.currentTarget.dataset.rowId
      this.formTargets.forEach(form => { form.style.display = 'none' })
      var element
      if(id == undefined) {
        element = $(".type_hours_forms form.new_row")
      } else (
        element = $(".type_hours_forms form[data-row-id='" + id + "']")
      )
      $(element).show()
      this.scrollTo(element)
    }
    event.preventDefault()
  }

  scrollTo(element) {
    var bootstrapBreakpointLG = 992
    var menuHeight = 120
    // Only scroll when typing is below the list
    if ($(window).width() < bootstrapBreakpointLG) {
      window.scroll({
        top: $(element).offset().top - menuHeight,
        behavior: 'smooth'
      })
    }
  }

  shouldChangeForm() {
    return !$(".type_hours_forms form:visible").data('isDirty') ||
    confirm($('.typed_hours_table').data('confirm-msg'))
  }

}
