import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["hourTypeInput", "amountTypeInput"]

  change(event) {
    var type = event.currentTarget.options[event.currentTarget.selectedIndex].dataset.type
    var toggle = type == 'amount'
    this.hourTypeInputTargets.forEach(input => { input.classList.toggle('hidden', toggle) })
    this.amountTypeInputTargets.forEach(input => { input.classList.toggle('hidden', !toggle) })
  }
}
