import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $(document.getElementById("popup_editor")).on('hidden.bs.modal', () => {
      location.reload()
    })

    $(document.getElementById("select_department")).on('change', (event) => {
      location = event.target.options[event.target.selectedIndex].getAttribute('href')
    })
  }
}
