import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    var options = {
      valueNames: [ 'type', 'priority', 'status', 'source', 'owner', 'worker', 'created', 'deadline' ]
    };

    var taskList = new List(this.element.id, options);
  }
}
