import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["selection"]

  print() {
    var printContents = this.selectionTarget.innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();

    document.body.innerHTML = originalContents;
  }
}
