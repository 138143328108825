import { Controller } from "stimulus"

export default class extends Controller {
  getForm() {
    $.ajax({
      type: "GET",
      url: this.data.get("url"),
      success: (data) => {
        this.showModal(data)
       }
    });
  }

  showModal(data) {
    let popupEditor = document.getElementById(this.data.get("editorId"))

    popupEditor.innerHTML = data
    $(popupEditor).modal()
  }

}
