import { Controller } from "stimulus"
import { DirectUpload } from "activestorage"
import * as FilePond from "filepond"

export default class extends Controller {
  static targets = ["fileField", "form"]

  connect() {
    const pond = FilePond.create(this.fileFieldTarget, this.options());

    pond.setOptions({
      server: {
        process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
          this.uploadFile(file, load)
        },
        revert: (uniqueFileId, load, error) => {
          this.removeFile(uniqueFileId, load)
        },
        remove: (source, load, error) => {
          this.removeFile(source, load)
        }
      },
      files: JSON.parse(this.data.get("uploadedFiles")).map(doc => this.buildFile(doc))
    });
  }

  buildFile(doc) {
    return {
      // the server file reference
      source: doc.source,
      // set type to local to indicate an already uploaded file
      options: {
        type: 'local',
        // mock file information
        file: {
          name: doc.name,
          size: doc.size,
          type: doc.content_type
        },
        // url in metadata for download of uploaded files
        metadata: {
          url: doc.url
        }
      }
    }
  }

  deleteUrl(source) {
    return "/rails/active_storage/blobs/" + source
  }

  uploadFile(file, load) {
    const upload = new DirectUpload(file, this.data.get("uploadUrl"))

    upload.create((error, blob) => {
      if (error) {
        // Handle the error
      } else {
        // Add an appropriately-named hidden input to the form with a
        //  value of blob.signed_id so that the blob ids will be
        //  transmitted in the normal upload flow
        load(blob.signed_id)
      }
    })
  }

  removeFile(source, load) {
    const request = new XMLHttpRequest();
    var controller = this
    request.addEventListener("load", function(event) {
      controller.element.dispatchEvent(controller.attachmentRemovedEvent(this.response))
    })
    request.responseType = 'json';
    request.open('DELETE', this.deleteUrl(source), true);
    request.setRequestHeader('X-CSRF-TOKEN', this.data.get('csrfToken'))
    request.send()

    load(request.response);
  }

  attachmentRemovedEvent(response) {
    return new CustomEvent('direct-upload:attachmentRemoved', {bubbles: true, cancelable: true, detail: response})
  }

  options() {
    const options = {
      name: 'filepond',
      allowDownloadByUrl: true
    }

    const mergedOptions = Object.assign(options, JSON.parse(this.data.get("translations")))

    return mergedOptions
  }
}
